import { getStorage } from '@/commons/SessionStorageUtils'
import { ResponseData } from '@/commons/Types'
import axios, { AxiosInstance, AxiosTransformer } from 'axios'
import { ElLoading, ElMessage } from 'element-plus'
import getResponseInterceport from './AxiosResponseIntercepor'
import { setStorage, removeStorage } from '@/commons/SessionStorageUtils'

let loading: any
const reqList: any[] = []
const baseURL = process.env.VUE_APP_BASE_API

const repeatMessage = '请勿重复提交'
const timeoutErrorMessage = '请求超时'

/**
 * 发送数据之前，添加头信息
 * @param config
 * @param headers
 */

const stopRepeatRequest = function (
  reqList: any[],
  url: any,
  cancel: any,
  errorMessage: any
) {
  const errorMsg = errorMessage || ''
  for (let i = 0; i < reqList.length; i++) {
    if (reqList[i] === url) {
      cancel(errorMsg)
      return
    }
  }
  reqList.push(url)
}

const allowRequest = function (reqList: any, url: any) {
  for (let i = 0; i < reqList.length; i++) {
    if (reqList[i] === baseURL + url) {
      reqList.splice(i, 1)
      break
    }
  }
}

const axiosTransformerRequestHeader: AxiosTransformer = (config, headers) => {
  // 添加头信息
  const token = getStorage<string>('Token')
  if (token) {
    headers['Authorization'] = token
  }

  const defaultRequest = axios.defaults.transformRequest
  if (Array.isArray(defaultRequest)) {
    defaultRequest.forEach((fn) => (config = fn(config, headers)))
  } else {
    defaultRequest && defaultRequest(config, headers)
  }
  return config
}

// create an axios instance
const service: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: '/web',
  transformRequest: axiosTransformerRequestHeader,
  withCredentials: true, // send cookies when cross-domain requests
  timeoutErrorMessage: timeoutErrorMessage,
  timeout: 100000 // request timeout
})
const axiosErrorHandle = (err: any): any => {
  if (err && err.config) {
    allowRequest(reqList, err.config.url)
  }
  loading.close()

  if (
    err &&
    err.message &&
    (err.message === repeatMessage || err.message === timeoutErrorMessage)
  ) {
    return Promise.reject(err.message)
  }

  return Promise.reject(err)
}
service.interceptors.request.use(
  (config) => {
    setStorage('ConfigUrl', config.url)
    loading = ElLoading.service({
      lock: true,
      text: '查询中请稍等',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    config.cancelToken = source.token
    const token = getStorage<string>('Token')
    if (token) {
      config.headers['Authorization'] = token
      if (config.method != 'get') {
        stopRepeatRequest(
          reqList,
          (baseURL as string) + config.url,
          source.cancel,
          repeatMessage
        )
      }
    }
    const hash = location.hash ?? ''
    const companyId = hash.match(/companyId=(\d+)/)?.[1]
    const organizationId = hash.match(/organizationId=(\d+)/)?.[1]
    if (config.data) {
      // console.log('🚀 ~ config.data:', typeof config.data, config.data)
      if (typeof config.data == 'object') {
        config.data.companyId = companyId
        config.data.organizationId = organizationId
      } else if (typeof config.data == 'string') {
        try {
          const data = JSON.parse(config.data)
          data.companyId = companyId
          data.organizationId = organizationId
          config.data = JSON.stringify(data)
          // console.log('🚀 ~ config.data:', config.data, data)
        } catch (e) {
          //
        }
      }
    }
    if (config.url?.includes('?') && config.url.includes('institutionId')) {
      config.url += `&companyId=${companyId}&organizationId=${organizationId}`
    }
    return config
  },
  (error) => {
    // do something with request error
    return Promise.reject(error)
  }
)
// add response interceprot
service.interceptors.response.use((response) => {
  removeStorage('ConfigUrl')
  loading.close()
  const responseData: ResponseData<any> = response.data
  const interceports = getResponseInterceport()
  allowRequest(reqList, response.config.url)
  if (response.data.code != 200) {
    ElMessage({
      type: 'error',
      message: response.data.msg || response.data.message
    })
  }
  const isSuccess = interceports.every((fn) => fn(responseData))
  if (!isSuccess) {
    return Promise.reject(response.data || 'error')
  }
  return response
}, axiosErrorHandle)

export default service
