import { ElMessage } from 'element-plus'
import { getRouterFullPath, recordJsLogFun } from './routerFullPathError'
import { getStorage, removeStorage } from '@/commons/SessionStorageUtils'
import { App, ComponentPublicInstance } from 'vue'
const u = navigator.userAgent;
const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
export default function initErrorHandle(app: App): void {
    const defaltErrorHandle = app.config.errorHandler
    app.config.errorHandler = function (err: any, instance: ComponentPublicInstance | null, info: string) {
        defaltErrorHandle && defaltErrorHandle(err, instance, info)
        // 将捕获的异常进行分析
        if (typeof err === 'string') {
            // 手动抛出的异常
            ElMessage.error(err)
        } else {
            const token = getStorage<string>('Token')
            const ConfigUrl = getStorage<string>('ConfigUrl')
            ElMessage.error({
                type: 'error',
                message: err && err.msg || err.message,
                duration: 3000
            })
            // recordJsLogFun(`FullPath:${getRouterFullPath()},token:${token},ConfigUrl:${ConfigUrl},err:${err},isiOS:${isiOS},isAndroid:${isAndroid}`)
            if (ConfigUrl && ConfigUrl != 'undefined') {
                removeStorage('ConfigUrl')
            }
            // object 信息上报
        }
        // 将err信息上报
        console.log('err', err, typeof err, 'xxp')
    }
}

/**
 * 手动抛出异常
 */
export function throwCustomFunction(err: any): void {
    throw err
}
