import AxiosAdapter from "../request/AxiosAdapter";
let getfullPathm = ''

export function setRouterFullPath(key: string): void {
    getfullPathm = key
}

export function getRouterFullPath() {
    return getfullPathm
}


export function recordJsLogFun(logStr: string) {
    const data: any = AxiosAdapter.get({
        url: "/webElectronic/recordJsLog",
        data: { 
          logStr,
        },
    });
}


