import { ElStep } from 'element-plus'
import { isString } from './TypeUtils'
import Cookies from 'js-cookie'

export type StoragePreKey = 'Token' | 'Key' | 'ConfigUrl'

export const TOKEN_KEY: StoragePreKey = 'Token'

let sessionStorage: any

function getCookieStorage(): Record<string, any> {
    return {
        setItem: Cookies.set,
        getItem: Cookies.get,
        removeStorage: Cookies.remove,
    }
}

try {
    // 1、验证当前环境是否支持sessionStorage, ios 版本过低不会支持 sessionStorage
    if (Reflect.has(window, 'sessionStorage')) {
        sessionStorage = window.sessionStorage
        // 2、验证是否 ios-无痕模式，如果是无痕模式，当前会抛出异常
        sessionStorage.setItem('company', 'glzx')
    } else {
        sessionStorage = getCookieStorage()
    }
} catch (e) {
    sessionStorage = getCookieStorage()
}


export function getStorage<T>(key: StoragePreKey): T {
    const item = sessionStorage.getItem(key)
    return item && (isString(item) ? item : JSON.parse(item))
}

export function setStorage(key: StoragePreKey, value: any): void {
    sessionStorage.setItem(key, isString(value) ? value : JSON.stringify(value))
}

export function removeStorage(key: StoragePreKey) {
    sessionStorage.removeItem(key)
}