import request from './Axios'
import { AxiosResponse, Method } from 'axios'
import { ResponseData, StringFunctionType } from '@/commons/Types'

const methodAndParams: StringFunctionType<Request<any>, Promise<AxiosResponse<ResponseData<any>>>> = {
    get(req) {
        return request.request({
            url: req.url,
            method: 'get',
            params: req.data
        })
    },
    GET(req) {
        return request.request({
            url: req.url,
            method: 'get',
            params: req.data
        })
    },
    post(req) {
        return request.request({
            url: req.url,
            method: 'post',
            data: req.data
        })
    },
    POST(req) {
        return request.request({
            url: req.url,
            method: 'post',
            data: req.data
        })
    }
}

export interface Request<T> {
    type?: Method;
    url: string;
    data?: T;
}

export default class AxiosAdapter {
    static request<T, K>(req: Request<T>):any {
        if (!req.type) req.type = 'get'
        const func = methodAndParams[req.type]
        return func(req).then(res => res.data.value)
    }
    static get<T, K>(req: Request<T>): Promise<K> {
        if (req) req.type = 'get'
        return AxiosAdapter.request<T, K>(req)
    }
    static post<T, K>(req: Request<T>): Promise<K> {
        if (req) req.type = 'post'
        return AxiosAdapter.request<T, K>(req)
    }
}
