import { getStorage } from '@/commons/SessionStorageUtils';
import { ResponseData } from '@/commons/Types';
import router from '../router/index'

type InterceptorsType = ((data: ResponseData<any>) => boolean)[]

const chains: InterceptorsType = [
    data => {
        if (data.code === 401) {
            const key = getStorage<string>('Key')
            if (router && key) {
                router.push({
                    path: "/",
                    query: {
                        k: key
                    }
                })
            }
            return true
        }
        return true
    },
    data => {
        if (data.code != 200) {
            throw data.msg || 'error'
        }
        return true
    },
]

export default function getResponseInterceport(): InterceptorsType {
    return chains
}
