import { removeStorage, setStorage } from '@/commons/SessionStorageUtils'
import { createStore } from 'vuex'

export interface StateProp {
  user: UserProp;
}

export interface UserProp {
  token: string;
}

const user: UserProp = {
  token: ''
}

export default createStore<StateProp>({
  state: {
    user
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
