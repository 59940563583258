import router from './router'
import store from './store'
import App from './App.vue'
import installElementPlus from './plugins/element'
import { createApp } from 'vue'
import initErrorHandle from './commons/ErrorHandle'
import eruda from 'eruda'

if (process.env.NODE_ENV != 'production') {
  eruda.init()
}

const app = createApp(App)

// install error handle
initErrorHandle(app)
// install element-plus
installElementPlus(app)
// install vuex vue-router
app.use(store).use(router).mount('#app')
