
export function isString(obj: any): boolean {
    return Object.prototype.toString.call(obj) === '[object String]';
}

export function isObject(obj: any): boolean {
    return Object.prototype.toString.call(obj) === '[object Object]';
}

export function isHtmlElement(node: any): boolean {
    return node && node.nodeType === Node.ELEMENT_NODE;
}

export const isFunction = (functionToCheck: any): boolean => {
    const getType = {};
    return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
};

export const isUndefined = (val: any): boolean  => {
    return val === void 0;
};

export const isDefined = (val: any): boolean => {
    return val !== undefined && val !== null;
};
  